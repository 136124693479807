import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const languagesList = [
    {
        value: "tr",
        label: "TR",
        img: `/images/flags/flag-tr.svg`,
    },
    {
        value: "en",
        label: "EN",
        img: `/images/flags/flag-uk.svg`,
    },
]

const useLanguageChange = () => {
    const [t, { changeLanguage }] = useTranslation(["translation"])
    const lang = localStorage?.getItem("i18nextLng")
    const [selectedLanguage, setSelectedLanguage] = useState(
        languagesList.find(el => el.value === lang) || languagesList[0],
    )

    useEffect(() => {
        if (changeLanguage) {
            changeLanguage(selectedLanguage.value)
        }
    }, [selectedLanguage])

    const onLanguageChange = language => {
        setSelectedLanguage(language)
        localStorage.setItem("i18nextLng", language.value)
    }

    return { onLanguageChange, languagesList, selectedLanguage }
}

export default useLanguageChange
