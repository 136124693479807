import LanguageSelector from "../LanguageSelector"
import "./header.scss"

const Header = () => {
    return (
        <header className="header">
            <div className="container">
                <img className="header__logo" src="/images/ptech-logo.svg" alt="Logo" />
                <div className="header__right" style={{ color: "#fff" }}>
                    <LanguageSelector />
                </div>
            </div>
        </header>
    )
}

export default Header
