// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 0;
}
@media screen and (max-width: 991.5px) {
  .header {
    padding: 20px 0;
  }
}
.header > .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__right .select-options, .header__right .selected-option {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 10px 20px;
}
.header__right .select-options img, .header__right .selected-option img {
  width: 20px;
  height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/header.scss","webpack://./src/assets/styles/Settings/screens.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,eAAA;AADF;ACEI;EDNJ;IAOI,eAAA;EACF;AACF;AAEE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAAJ;AAII;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,kBAAA;AAFN;AAGM;EACE,WAAA;EACA,YAAA;AADR","sourcesContent":["@import \"../../assets/styles/Settings/screens.scss\";\n\n.header {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  padding: 40px 0 ;\n  @include phone {\n    padding: 20px 0;\n  }\n  \n\n  > .container {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n  \n  &__right {\n    .select-options, .selected-option {\n      cursor: pointer;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      gap: 4px;\n      padding: 10px 20px;\n      img {\n        width: 20px;\n        height: 20px;\n      }\n    }\n  }\n}\n","$phone-max: 991.5px;\n$phone-min: 992px;\n$phone-xs: 540px;\n$tablet-min: 992px;\n$tablet-max: 1200px;\n$desktop-min: 1200.5px;\n\n@mixin phone {\n    @media screen and (max-width: $phone-max) {\n        @content;\n    }\n}\n@mixin phone-xs {\n    @media screen and (max-width: $phone-xs) {\n        @content;\n    }\n}\n\n@mixin phone-min {\n    @media screen and (min-width: $phone-min) {\n        @content;\n    }\n}\n@mixin tablet {\n    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {\n        @content;\n    }\n}\n@mixin tablet-min {\n    @media screen and (max-width: ($tablet-max - 1)) {\n        @content;\n    }\n}\n@mixin from-tablet {\n    @media screen and (min-width: $tablet-min) {\n        @content;\n    }\n}\n@mixin desktop {\n    @media screen and (min-width: $desktop-min) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
