// organize-imports-ignore
import { Header, InfoBox, Bottom } from "./components"
import "./assets/styles/styles.scss"
import "./app.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import { Autoplay } from "swiper/modules"
// eslint-disable-next-line no-unused-vars
import i18n from "./utilities/i18n"
import Hero from "./components/Hero"
import { useTranslation } from "react-i18next"
import useMatchMedia from "./utilities/functions/useMatchMedia";



function App() {
    const [t] = useTranslation(["translation"])
    const [isMobile] = useMatchMedia()
    const data = [
        {
            title: t("solutions.token.title"),
            desc: t("solutions.token.description"),
            image: "solutions-token",
        },
        {
            title: t("solutions.wallet.title"),
            desc: t("solutions.wallet.description"),
            image: "solutions-wallet",
        },
        {
            title: t("solutions.pos.title"),
            desc: t("solutions.pos.description"),
            image: "solutions-pos",
        },
        {
            title: t("solutions.open-banking.title"),
            desc: t("solutions.open-banking.description"),
            image: "vpos",
        },
        {
            title: t("solutions.blockchain.title"),
            desc: t("solutions.blockchain.description"),
            image: "solutions-blockchain",
        },
        {
            title: t("solutions.digital-banking.title"),
            desc: t("solutions.digital-banking.description"),
            image: "wallet",
        },
    ]

    const data2 = [
        {
            title: t("services.token.title"),
            desc: t("services.token.description"),
            image: "services-token.svg",
        },
        {
            title: t("services.wallet.title"),
            desc: t("services.wallet.description"),
            image: "services-wallet.svg",
        },
        {
            title: t("services.pos.title"),
            desc: t("services.pos.description"),
            image: "services-pos.svg",
        },
        {
            title: t("services.open-banking.title"),
            desc: t("services.open-banking.description"),
            image: "services-open.svg",
        },
        {
            title: t("services.blockchain.title"),
            desc: t("services.blockchain.description"),
            image: "services-blockchain.svg",
        },
        {
            title: t("services.digital-banking.title"),
            desc: t("services.digital-banking.description"),
            image: "services-digital.svg",
        },
    ]

    return (
        <>
            <section className="top">
                <Header />
                <Hero />
            </section>
            <section className="section-first">
                <div className="cards-wrapper">
                    <InfoBox
                        src="send-plane-2-line.svg"
                        title={t("about.story.title")}
                        text={t("about.story.description")}
                    />
                    <InfoBox
                        src="lightbulb-flash-line.svg"
                        title={t("about.vision.title")}
                        text={t("about.vision.description")}
                    />
                    <InfoBox
                        src="focus-3-line.svg"
                        title={t("about.mission.title")}
                        text={t("about.mission.description")}
                    />
                </div>
            </section>
            <section className="section-second">
                <div className="section__title">{t("services.title")}</div>
                <Swiper
                    slidesPerView="auto"
                    slidesPerGroup={1}
                    loop={true}
                    spaceBetween={30}
                    slidesOffsetBefore={isMobile ? 20 : 100}
                    autoplay={{
                        delay: 2500,
                    }}
                    modules={[Autoplay]}
                >
                    {data2.map(item => {
                        return (
                            <SwiperSlide>
                                <InfoBox
                                    src={item.image}
                                    type="v2"
                                    title={item.title}
                                    text={item.desc}
                                />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </section>
            <section className="section-products">
                <div className="container">
                    {data.map(item => {
                        return (
                            <div className="box">
                                <img
                                    className="box__img"
                                    src={`/images/${item.image}.png`}
                                    srcSet={`/images/${item.image}@2x.png 2x, /images/${item.image}.png 1x`}
                                    alt="Box"
                                />
                                <div className="box__content">
                                    <span className="box__title">{item.title}</span>
                                    <p className="box__desc">{item.desc}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>
            <Bottom />
        </>
    )
}

export default App
