import classNames from "classnames"
import './info-box.scss';

const InfoBox = ({ src, title, text, type }) => {
    return (
        <div className={classNames('info-box', {'info-box--v2': type === 'v2'})}>
            <img className="info-box__img"
                 src={`/images/${src}`}
                 alt="Info Box"
            />
            <span className="info-box__title">{title}</span>
            <span className="info-box__text">{text}</span>
        </div>
    )
}

export default InfoBox;
