import "./hero.scss";
import { useTranslation } from "react-i18next"

const Hero = () => {
    const [t] = useTranslation(["translation"])
    return (
        <div className="hero">
            <div className="container">
                <div className="hero__left">
                    <h1>
                        {t("header-1")}<br />{t("header-2")}<br />{t("header-3")}
                    </h1>
                </div>
                <div className="hero__right">
                    <img src="/images/papel-logo.svg" alt="Papel" />
                </div>
                <div className="hero__arrow">
                    <img src="/images/arrow-down.svg" alt="" />
                </div>
            </div>
        </div>
    )
}

export default Hero
