import Select from "react-select"
import useLanguageChange from "../../utilities/functions/useLanguageChange"

const LanguageSelector = () => {
    const { onLanguageChange, languagesList, selectedLanguage } = useLanguageChange()

    return (
        <>
            <Select
                options={languagesList}
                onChange={onLanguageChange}
                value={selectedLanguage}
                styles={{
                    control: baseStyles => ({
                        ...baseStyles,
                        placeholderColor: "red",
                        border: "none",
                        color: "white",
                        height: "40px",
                        borderRadius: "10px",
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        caretColor: "transparent",
                        boxShadow: "none",
                        ":focus": {
                            boxShadow: "none",
                        },
                    }),
                    menu: baseStyles => ({
                        ...baseStyles,
                        background: "transparent",
                        border: "none",
                        boxShadow: "none",
                        ":focus": {
                            border: "none",
                        },
                    }),
                    indicatorsContainer: baseStyles => ({
                        ...baseStyles,
                        display: "none", // Hide the dropdown indicator separator
                    }),
                    indicatorSeparator: baseStyles => ({
                        ...baseStyles,
                        display: "none", // Hide the dropdown indicator separator
                    }),
                    dropdownIndicator: baseStyles => ({
                        ...baseStyles,
                        display: "none", // Hide the dropdown indicator separator
                    }),
                    valueContainer: baseStyles => ({
                        ...baseStyles,
                        display: "flex",
                        border: "none",
                        alignItems: "center",
                        color: "white",
                        background: "transparent",
                        ":focus": {
                            border: "none",
                        },
                    }),
                    input: baseStyles => ({
                        ...baseStyles,
                        color: "white",
                    }),
                    singleValue: baseStyles => ({
                        ...baseStyles,
                        color: "white",
                    }),
                }}
                components={{
                    Option: ({ data, ...props }) => {
                        console.log(props.getValue()[0].value)
                        const current = props.getValue()[0].value
                        return (
                            <div
                                onClick={() => props.selectOption(data)}
                                style={data.value === current ? { display:"none" } : { display:"flex" }}
                                className="select-options"
                            >
                                <img src={data.img} alt="" />
                                {data.label}
                            </div>
                        )
                    },
                    SingleValue: ({ data, ...props }) => {
                        return (
                            <div className="selected-option">
                                <img src={data.img} alt="" />
                                {data.label}
                            </div>
                        )
                    },
                }}
            />
        </>
    )
}

export default LanguageSelector
