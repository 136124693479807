import React from 'react'
import './ptech.scss'
import { useTranslation } from "react-i18next"

const PTech = () => {
    const [t] = useTranslation(["translation"])
    const toUp = () =>{
        window.scrollTo(0,0)

    }
    return (
        <>
            <div className="newsletter">
                <div className="container">
                    <div className="newsletter__content">
                        <div className="text">
                <span className="title">
                    {t("newsletter.title")}
                </span>
                <span className="description">
                            {t("newsletter.description")}
                </span>
                        </div>
                        <div className="action">
                            <div className="input-group">
                                <input className='newsletter-input' placeholder={t("newsletter.placeholder")}/>
                                <button>{t("newsletter.button")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="map">
                <div className="container">
                    <div className="text">
                        <span className='title'>{t("map.title") }</span>
                        <span className="address">{t("map.address") }</span>
                        <span className="email">info@p.tech</span>
                        <img src="/images/ptech-filig.png" alt="ptech" />
                    </div>
                    <div className="map-frame">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3006.2016222574052!2d29.012364111880487!3d41.1082875131407!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab5aa7a08efc1%3A0x7e60e6ad2ed4940!2sMaslak%20Link%20Plaza!5e0!3m2!1str!2str!4v1708457463668!5m2!1str!2str"
                            allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <div className="container">
                    <div className="footer-main">
                        <img src="/images/footer-logo.svg" alt="ptech" />
                        <img
                            className="to-up"
                            onClick={toUp}
                            src="/images/arrow-up.png"
                            alt="ptech"
                        />
                    </div>
                </div>
                <div className="copyright">
                    <span>{t("footer.copyright") }</span>
                </div>
            </footer>
        </>
    )
}

export default PTech
