// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-box {
  max-width: 380px;
  border-radius: 12px;
  box-sizing: border-box;
}
.info-box--v2 {
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: #0E0D26;
  padding: 40px 40px;
  min-height: 428px;
}
.info-box__img {
  display: block;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
}
.info-box__title {
  display: block;
  margin-bottom: 16px;
  color: #FFF;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: -0.26px;
}
.info-box__text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}`, "",{"version":3,"sources":["webpack://./src/components/InfoBox/info-box.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;EACA,sBAAA;AACF;AACE;EACE,2CAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;AACJ;AAEE;EACE,cAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;AAAJ;AAGE;EACE,cAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,uBAAA;AADJ;AAIE;EACE,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,gBAAA;EACA,oBAAA;EACA,qBAAA;EACA,aAAA;EACA,4BAAA;AAFJ","sourcesContent":[".info-box {\n  max-width: 380px;\n  border-radius: 12px;\n  box-sizing: border-box;\n\n  &--v2 {\n    border: 1px solid rgba(255, 255, 255, 0.25);\n    background: #0E0D26;\n    padding: 40px 40px;\n    min-height: 428px;\n  }\n\n  &__img {\n    display: block;\n    margin-bottom: 10px;\n    width: 100px;\n    height: 100px;\n  }\n\n  &__title {\n    display: block;\n    margin-bottom: 16px;\n    color: #FFF;\n    font-size: 26px;\n    font-weight: 500;\n    letter-spacing: -0.26px;\n  }\n\n  &__text {\n    color: rgba(255, 255, 255, .7);\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    letter-spacing: -0.16px;\n    overflow: hidden;\n    display: -webkit-box;\n    -webkit-line-clamp: 6;\n    line-clamp: 2;\n    -webkit-box-orient: vertical;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
