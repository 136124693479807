import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

// Function to set the language in localStorage
const setLanguageInLocalStorage = lng => {
    localStorage.setItem("i18nextLng", lng)
}

i18n.use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: localStorage.getItem("i18nextLng") || "tr",
        fallbackLng: "en",
        debug: false,
        react: {
            useSuspense: true,
        },
        ns: ["translation"],
        defaultNS: "translation",
        detection: {
            order: ["localStorage", "navigator"],
            lookupLocalStorage: "i18nextLng",
            caches: ["localStorage"],
            callback: lng => {
                setLanguageInLocalStorage(lng)
            },
        },
    })

export default i18n
